<template>
  <div>
    <transition name="fade">
      <LoadingMask v-if="onload" />
    </transition>
    <div class="c-app" v-if="!onload">
      <TheSidebar />
      <div class="c-wrapper">
        <TheHeader />
        <div class="c-body bg-white">
          <b-overlay
            :show="mainLoading"
            rounded="sm"
            class="h-100 w-100"
            opacity="0.75"
          >
            <main class="c-main">
              <CContainer fluid>
                <transition name="fade">
                  <b-row class="justify-content-md-center">
                    <b-col cols="12" v-if="$route.meta.isFullScreen">
                      <router-view :key="$route.fullPath"></router-view>
                    </b-col>
                    <b-col v-else cols="12" md="11" class="p-4 page-content">
                      <router-view :key="$route.fullPath"></router-view>
                    </b-col>
                  </b-row>
                </transition>
              </CContainer>
            </main>
          </b-overlay>
        </div>
        <!-- <TheFooter /> -->
      </div>
      <SlidebarCustom ref="SlidebarCustom" />
    </div>
  </div>
</template>

<script>
import SlidebarCustom from './SlidebarCustom';
import TheSidebar from './TheSidebar';
import TheHeader from './TheHeader';
import TheFooter from './TheFooter';
import { mapGetters } from 'vuex';
import LoadingMask from '@/views/pages/LoadData';

export default {
  name: 'TheContainer',
  components: {
    TheSidebar,
    TheHeader,
    TheFooter,
    LoadingMask,
    SlidebarCustom
  },
  computed: {
    ...mapGetters(['isLoading']),
    ...mapGetters({
      mainLoading: 'getMainLoading',
      branchId: 'getBranchId'
    })
  },
  data() {
    return {
      onload: true,
      isUseClass: true,
      firstPathFromPermission: '/batch'
    };
  },
  watch: {
    '$route.fullPath': function () {
      this.checkRouter();
    }
  },
  methods: {
    checkRouter() {
      let menuCurrent =
        this.$route.path == '/home'
          ? 1
          : this.$route.path == '/report'
          ? 2
          : this.$route.path == '/point'
          ? 3
          : this.$route.path == '/customer'
          ? 4
          : this.$route.path == '/create-sale-order'
          ? 5
          : this.$route.path == '/sale-order'
          ? 6
          : this.$route.path == '/sale-order';
      this.$store.dispatch('setSlideBarCurrent', menuCurrent);
    },
    // getDataProfile: function () {
    //   var admin_token = this.$cookies.get("token");
    //   if (admin_token) {
    //     this.axios.get(`${this.$baseUrl}/user`).then((res) => {
    //       res.detail.token = admin_token;
    //       this.$store.dispatch("setUserData", res.detail);
    //     });
    //     this.getBrandList();
    //   }
    //   this.setTimeStapm();
    // },
    // getBrandList: function () {
    //   var admin_brand_id = this.$route.query.brandID;
    //   this.axios.get(`${this.$baseUrl}/brand/list`).then((res) => {
    //     if (res.result) {
    //       this.$store.dispatch("setBrandList", res.detail);
    //       if (!admin_brand_id) {
    //         this.$store.dispatch(
    //           "setCurrentBrandID",
    //           this.$store.getters.getBrandList()[0].id
    //         );
    //         this.$router.replace({
    //           path: this.firstPathFromPermission,
    //           query: { brandID: this.$store.getters.getBrandList()[0].id },
    //         });
    //       } else {
    //         let brandPermission = this.$store.getters
    //           .getBrandList()
    //           .find((el) => el.id == admin_brand_id);
    //         if (brandPermission) {
    //           this.$store.dispatch("setCurrentBrandID", admin_brand_id);
    //         } else {
    //           this.$store.dispatch(
    //             "setCurrentBrandID",
    //             this.$store.getters.getBrandList()[0].id
    //           );
    //           this.$router.replace({
    //             path: this.firstPathFromPermission,
    //             query: { brandID: this.$store.getters.getBrandList()[0].id },
    //           });
    //         }
    //       }
    //     }
    //   });
    // },
    setTimeStapm() {
      this.$store.watch(
        state => {
          return this.isLoading();
        },
        val => {
          console.log(val);
          if (!this.isLoading()) {
            setTimeout(() => {
              this.onload = false;
            }, 1500);
          }
        },
        {
          deep: true
        }
      );
    },
    async getTheme() {
      var admin_token = this.$cookies.get('token');
      if (admin_token) {
        let userData = {
          token: admin_token,
          user: { email: this.$cookies.get('tnl-username') },
          brands: null,
          permissions: null
        };

        await this.$store.dispatch('setUserData', userData);
      }
      // this.setTimeStapm();
      this.onload = false;
    },
    async checkSalesNo() {
      if (this.$cookies.get('sales_no')) {
        await this.$store.commit(
          'UPDATE_SALES_NO',
          this.$cookies.get('sales_no')
        );
        // this.$store.dispatch("setSalesNo", this.$cookies.get("sales_no"));
      } else {
        this.$router.push({ path: `/employee/info` }).catch(() => {});
      }
    }
  },
  created: async function () {
    // await this.checkSalesNo();
    this.checkRouter();
    this.getTheme();
  }
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.width-full {
  width: 100%;
}
::v-deep .b-overlay,
::v-deep .b-overlay > div {
  z-index: 1500 !important;
}
.c-app {
  overflow: hidden !important;
}
</style>
